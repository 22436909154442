html,
body {
  overflow-x: hidden;
}
body {
  padding-right: 0 !important;
}

html{
  background-color: black;
}
.footer{
  text-align: center; 
  background: white;
  height: 100%;
  padding: 0 0 8em 0;
  .main-title{
    margin-top: 0.6em;
  }
  .content__block{
    text-align: center !important;
  }
}
.main-title{
  font-size: 2.4em;
  font-weight: 600;
  margin: 0;
  padding: 1.2em 0 0.8em 0;

}
@media(min-width: 500px){
  .main-title{
    font-size: 2.6em;
    padding: 1.4em 0 1em 0;
  }
}
.bridge{
  background-repeat: no-repeat;
  -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
.bridge--light{
  height: 200px;
  background: linear-gradient(-185deg,#fff6df 50%,white 50%);
}
.bridge--dark{
  background: linear-gradient(-185deg,black 50%,#fff6df 50%);
}
.blinking{
  animation:blinkingText 1.2s ease-in-out infinite;
}
@keyframes blinkingText{
  0%{     color: #000;    }
  49%{    color: #000; }
  60%{    color: transparent; }
  99%{    color:transparent;  }
  100%{   color: #000;    }
}

.container {
  text-align: center;
  width: 100%;
  height: 100%;
  margin: auto;
    background-repeat: no-repeat;

  .intro__text {
    margin-top: 8.4em;
    margin-bottom: 4em;

    path {
      stroke-dasharray: 1000;
      stroke-dashoffset: 1000;
      animation-delay: 1s;
      animation: dash 8s linear forwards;
    }
    
    @keyframes dash {
      to {
        stroke-dashoffset: 0;
      }
    }
  }
}
.content{
  background-color: #fff6df;
}
.content__block{
  max-width: 700px;
  text-align: left;
  margin: auto;
  padding: 0 20px 0 20px;

}
.content__title{
  font-size: 2.2em;
  font-weight: bold;
  margin: 1em 0 0 0;
}
.content__subtitle{
  font-size: 1.6em;
  font-weight: 500;
  margin: 0.4em 0 0 0;
}
@media(min-width: 500px){
  .content__title{
    font-size: 2.4em;
  }
  .content__subtitle{
    font-size: 1.8em;
  }
}
.content__text{
    font-size: 1.2em;
}
.content--list{ 
  margin: 0;
  padding: 0.6em 0 1em 2em;
  li{
    padding: 0.2em 0;
  }
}
.content--link{
  display: inline;
  text-decoration: none;
  color: black;
  font-weight: 600;
  transition: 0.3s;
}
.content--link:hover{
  opacity: 0.8;
}
.content--highlight-red{
  background-color: #ffa09d;
}
.content--highlight-orange{
  background-color: #ffd380;
}
.content--highlight-blue{
  background-color: #80e1ff;
}
.content--highlight-green{
  background-color: #80ff95;
}
.content--highlight-purple{
  background-color: #df9bff;
}
.content--highlight-pink{
  background-color: #ffa1d0;
}
.content--highlight-light{
  background-color: #e4e4e4;
}

.profile__pic{
  max-width: 350px;
}
@media(min-width: 500px){
  .profile__pic{
    max-width: 400px;
  }
}